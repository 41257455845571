export const itemsSuperAdmin = [
  {
    role: 'student',
    title: 'Lernende',
    icon: 'mdi-account',
    group: 'sa',
    children: [
      {
        title: 'Noten',
        group: 'sa',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks',
          },
          {
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks',
          },
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks',
          },
          {
            title: 'Abschlussnoten',
            icon: 'mdi-circle-medium',
            to: 'final-marks',
          },
        ],
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'timetable',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },

  //   {
  //     subheader: "Apps",
  //     icon: "mdi-apps-box",
  //     divider: true
  //   },

  {
    role: 'parent',
    title: 'Eltern',
    icon: 'mdi-account-supervisor-circle',
    group: 'sa',
    children: [
      {
        title: 'Kindern',
        icon: 'mdi-chevron-right',
        to: 'children',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },
  {
    role: 'company',
    title: 'Praktikumsbetriebe',
    icon: 'mdi-domain',
    group: 'sa',
    children: [
      {
        title: 'Praktikanten ',
        icon: 'mdi-chevron-right',
        to: 'interns',
      },
      {
        title: 'Dokumente',
        icon: 'mdi-chevron-right',
        to: 'file-manager',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
      {
        title: 'Praxisnoten',
        icon: 'mdi-chevron-right',
        to: 'intern-marks',
      },
    ],
  },

  {
    role: 'teacher',
    title: 'PMA',
    icon: 'mdi-account-tie',
    group: 'sa',
    children: [
      {
        title: 'Noten',
        group: 'sa',
        children: [
          {
            title: 'Erfahrungsnoten',
            icon: 'mdi-circle-medium',
            to: 'experience-marks-pma',
          },
          {
            title: 'Zwischenbericht',
            icon: 'mdi-circle-medium',
            to: 'quarter-marks-pma',
          },
          {
            title: 'Semesternoten',
            icon: 'mdi-circle-medium',
            to: 'semester-marks-pma',
          },
          {
            title: 'Noteneingabe',
            icon: 'mdi-circle-medium',
            to: 'final-marks-pma',
          },
        ],
      },
      {
        title: 'Stundenplan',
        icon: 'mdi-chevron-right',
        to: 'timetable-pma',
      },
      {
        title: 'Verfügbarkeiten',
        icon: 'mdi-chevron-right',
        to: 'availability',
      },
      {
        title: 'Pensum',
        icon: 'mdi-chevron-right',
        to: 'workload',
      },
      {
        title: 'Abwesenheiten',
        icon: 'mdi-chevron-right',
        to: 'absence',
      },
      {
        title: 'Klassen',
        icon: 'mdi-chevron-right',
        to: 'courses',
      },
      {
        title: 'Präsenzkontrolle',
        icon: 'mdi-chevron-right',
        to: 'presence-pma',
      },
      {
        title: 'Linkliste',
        icon: 'mdi-chevron-right',
        to: 'link',
      },
    ],
  },
]
